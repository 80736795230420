import styled from "styled-components";

const RegulationsRulesStyles = {
    Container: styled.div`
        position: relative;
        width: 100%;
        /* height: 50vh; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-flow: column;
        padding-bottom: 100px;
    `,

    Title: styled.div`
        font-size: 3rem;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
        margin-top: 80px;
        @media (max-width: 800px) {
            font-size: 2rem;
            text-align: left;
            width: 80%;
            margin-top: 50px;
        }
    `,

    RulesContainer: styled.div`
        width: 80%;
        max-width: 1500px;
    `,

    RulesTitle: styled.div`
        font-size: 2rem;
        font-weight: 600;
        margin-top: 70px;
        margin-bottom: 50px;
        @media (max-width: 800px) {
            font-size: 1.6rem;
            margin-top: 40px;
            margin-bottom: 30px;
        }
    `,

    RulesBullets: styled.ul`
        list-style-position: outside;
        margin: 0;
        padding: 0 25px;
    `,

    RulesText: styled.li`
        font-size: 1.4rem;
        list-style-type: disc;
        margin-bottom: 10px;
        /* text-align: justify; */
        @media (max-width: 800px) {
            font-size: 1.4rem;
        }
    `,

    RuleBackgroundSquares: styled.div`
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        @media (max-width: 800px) {
            display: none;
        }
    `,

    RuleBackgroundSquaresBottom: styled.div`
        position: absolute;
        right: 0;
        bottom: -10px;
        z-index: -1;
        @media (max-width: 800px) {
            display: none;
        }
    `,
};

export default RegulationsRulesStyles;
