import React from "react";
import RegulationsRulesStyles from "./RegulationsRules.styles";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";

const {
    Container,
    Title,
    RulesContainer,
    RulesTitle,
    RulesBullets,
    RulesText,
    RuleBackgroundSquares,
    RuleBackgroundSquaresBottom,
} = RegulationsRulesStyles;

const Regulations = () => {
    const data = useStaticQuery(graphql`
        query Regulations {
            mdx(fields: { source: { eq: "regulations" } }) {
                body
                id
            }
        }
    `);

    return (
        <Container>
            <RuleBackgroundSquares>
                <StaticImage
                    src="../../../images/Regulations/rulesBG.png"
                    alt="regulations bg squares"
                    placeholder="blurred"
                    // quality={100}
                    // layout="fullWidth"
                />
            </RuleBackgroundSquares>
            <Title>Regulamin kuźni talentów oakfusion</Title>
            <RulesContainer>
                <MDXProvider
                    components={{
                        h1: (props) => <RulesTitle {...props} />,
                        li: (props) => <RulesText {...props} />,
                        ul: (props) => <RulesBullets {...props} />,
                    }}
                >
                    <MDXRenderer>{data.mdx.body}</MDXRenderer>
                </MDXProvider>
            </RulesContainer>
            <RuleBackgroundSquaresBottom>
                <StaticImage
                    src="../../../images/Regulations/rulesBG-bottom.png"
                    alt="regulations bg squares"
                    placeholder="blurred"
                    // quality={100}
                    // layout="fullWidth"
                />
            </RuleBackgroundSquaresBottom>
        </Container>
    );
};

export default Regulations;
