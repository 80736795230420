import * as React from "react";
import RegulationsHome from "../components/Regulations/Home/RegulationsHome";
import RegulationsRules from "../components/Regulations/Regulations/RegulationsRules";
import Layout from "../components/Layout/Layout";
import { Helmet } from "react-helmet";

const Regulations = () => {
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>OakForge - Regulations</title>
                <meta
                    name="description"
                    content="Oakforge - your best starting place in IT world"
                />
            </Helmet>
            <RegulationsHome />
            <RegulationsRules />
        </Layout>
    );
};

export default Regulations;
