import styled from "styled-components";

const RegulationsHomeStyles = {
    Container: styled.div`
        width: 100%;
        height: max-content;
        position: relative;
        /* border: 2px solid black; */
    `,
    ImageContainer: styled.div`
        /* object-fit: cover; */
        /* width: 101%; */
        position: relative;
        /* top: -5px;
        left: -1%; */
    `,

    TitleContainer: styled.div`
        position: relative;
        width: 100%;
        /* height: 100%; */
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
    `,

    TitleBoxContainer: styled.div`
        position: absolute;
        width: 500px;
        height: 100px;
        left: 13%;
        bottom: 100px;
        border-bottom: 2px solid rgba(255, 255, 255, 0.6);
        display: flex;
        justify-content: center;
        align-items: flex-end;
        @media (max-width: 800px) {
            left: 10%;
            bottom: 50px;
            width: 400px;
        }
    `,

    TitleBox: styled.div`
        width: 450px;
        height: 30px;
        text-align: center;
        border-left: 2px solid rgba(255, 255, 255, 0.6);
        border-right: 2px solid rgba(255, 255, 255, 0.6);
        @media (max-width: 800px) {
            width: 350px;
        }
    `,

    TitleText: styled.div`
        font-family: "Barcade";
        font-size: 1.8rem;
        color: #eefc57;
        text-shadow: 0px 0px 30px #eefc57;
        position: relative;
        bottom: 20px;
        @media (max-width: 800px) {
            font-size: 1.6rem;
            bottom: 10px;
        }
    `,
};

export default RegulationsHomeStyles;
