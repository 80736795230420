import React from "react";
import RegulationsHomeStyles from "./RegulationsHome.styles";
import { StaticImage } from "gatsby-plugin-image";

const {
    Container,
    ImageContainer,
    TitleContainer,
    TitleBoxContainer,
    TitleBox,
    TitleText,
} = RegulationsHomeStyles;

const RegulationsHome = () => {
    return (
        <Container>
            <ImageContainer>
                <StaticImage
                    src="../../../images/Regulations/homeBG.png"
                    alt="regulations background"
                    placeholder="blurred"
                    quality={100}
                    layout="fullWidth"
                />
            </ImageContainer>
            <TitleContainer>
                <TitleBoxContainer>
                    <TitleBox>
                        <TitleText>Regulations</TitleText>
                    </TitleBox>
                </TitleBoxContainer>
            </TitleContainer>
        </Container>
    );
};

export default RegulationsHome;
